import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 行政区划查询
 */
export const industry = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/industry',
        params
    })
}
/**
 * 智能分析搜索
 */
export const search = (params) => {
    return $api.post({
        url: baseURL + '/api/intelligent/search',
        params
    })
}
/**
 * 基本信息-工商信息
 */
export const basicInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/basicInfo',
        params
    })
}
/**
 * 基本信息-出资人信息
 */
 export const shareholderInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/shareholderInfo',
        params
    })
}
/**
 * 公共信息--信息概要
 */
export const publicInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/publicInfo',
        params
    })
}
/**
 * 公共信息--欠费信息
 */
export const publicOweInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/publicOweInfo',
        params
    })
}
/**
 * 经营信息--信息概要
 */
export const manageInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/manageInfo',
        params
    })
}

/**
 * 经营信息--经营者信息
 */
export const operatorInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/operatorInfo',
        params
    })
}


/**
 * 经营信息--预付交易状况
 */
export const transactionsInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/transactionsInfo',
        params
    })
}

/**
 * 经营信息--资金监管状况--第一个
 */
export const moneyInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/moneyInfo',
        params
    })
}

/**
 * 经营信息--资金监管状况--第二个
 */
export const insuredInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/insuredInfo',
        params
    })
}

/**
 * 经营信息--投诉受理状况
 */
export const complainInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/complainInfo',
        params
    })
}

/**
 * 经营信息--风险处置
 */
export const damagedInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/damagedInfo',
        params
    })
}

/**
 * 预付合同签约、履约、违约情况饼图
 */
 export const contractStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/contractStatistics',
        params
    })
}

/**
 * 消费人群和消费金额饼图
 */
 export const consumerStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/consumerStatistics',
        params
    })
}

/**
 * 疫情防控累计趋势
 */
 export const epidemicPreventionCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/intelligent/epidemicPreventionCumulative',
        params
    })
}

/**
 * 预付交易状况 预收资金监管情况 投诉受理状况 风险处置情况 门店防疫情况
 */
export const getPdfListInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/getPdfListInfo',
        params
    })
}

/**
 * 预付交易状况 预收资金监管情况 投诉受理状况 风险处置情况 门店防疫情况
 */
export const getSafeCodeColor = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/getSafeCodeColor',
        params
    })
}

/**
 * pdf下载
 */
export const exportPdf = (params) => {
    return $api.get({
        url: baseURL + '//api/intelligent/exportPdf',
        params
    })
}

/**
 * 结论及风险提示
 */
 export const getOverDes = (params) => {
    return $api.get({
        url: baseURL + '/api/intelligent/getOverDes',
        params
    })
}
