<template>
  <div>
    <el-button
        @click="prints"
        v-if="showBtn"
        type="primary"
        style="position: absolute; left: 20px; top: 20px"
    >打印</el-button
    >
    <!--startprint--><!--注意要加上html里star和end的这两个标记-->
    <div
        lang="ZH-CN"
        style="text-justify-trim: punctuation"
        class="pdf-box"
        ref="pdfBox"
    >
      <div class="WordSection1" style="layout-grid: 15.6pt">
        <p class="MsoNormal">
          <span style="position: relative; z-index: -1895825408" class="fl">
            <span
                style="left: 0px; position: absolute; width: 264px; height: 30px"
            >
              <img
                  width="264"
                  height="30"
                  src="./word2.fld/image001.png"
                  alt="图片1"
              />
            </span>
          </span>
          <span lang="EN-US"></span>
          <span
              lang="EN-US"
              class="fr"
              style="font-size: 12pt; font-family: '等线 Light'"
          >NO.{{ new Date().getTime() }}</span
          >
        </p>

        <p class="MsoNormal">
          <span lang="EN-US"><br /></span>
        </p>

        <p class="MsoNormal">
          <span lang="EN-US"><br /></span>
        </p>

        <p class="MsoNormal">
          <span lang="EN-US"><br /></span>
        </p>

        <p class="MsoNormal">
          <span lang="EN-US"><br /></span>
        </p>

        <p class="MsoNormal">
          <span lang="EN-US" style="font-size: 26pt"><br /></span>
        </p>

        <p class="MsoNormal">
          <span lang="EN-US" style="font-size: 26pt"><br /></span>
        </p>

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 300%"
        >
          <span style="font-size: 26pt; line-height: 300%; font-family: 宋体"
          >{{ location }}单用途预付消费卡</span
          ><span
            style="
              position: absolute;
              z-index: -1895827456;
              left: calc(50% - 157px);
              margin-top: 1px;
              width: 314px;
              height: 315px;
            "
        ><img
            width="314"
            height="315"
            src="./word2.fld/image002.png"
            alt="9999" /></span
        ><span lang="EN-US" style="font-size: 26pt; line-height: 300%"
        ><br /> </span
        ><span style="font-size: 26pt; line-height: 300%; font-family: 宋体"
        >经营者商务信用及风险状况评估报告</span
        ><b
        ><span lang="EN-US" style="font-size: 26pt; line-height: 300%"
        ><br /> </span></b
        ><span style="font-size: 15pt; height: 45pt; font-family: 宋体"
        >（<span lang="EN-US">PDF</span>版）</span
        >
        </p>

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 300%"
        >
          <span
              lang="EN-US"
              style="font-size: 15pt; height: 45pt; font-family: 宋体"
          ><br />
            <br />
            <br />
          </span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="margin-left: 63pt; text-align: left; line-height: 300%"
        >
          <span style="font-size: 14pt; line-height: 300%; font-family: 楷体"
          >经营者名称：{{ operatorInfo.busName || "--" }}
            <span lang="EN-US"><br /> </span>经营者编号：<span lang="EN-US">{{
                basicInfo.creditNo || "--"
              }}</span></span
          >
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="
            margin-left: 63pt;
            text-align: left;
            text-indent: 14pt;
            line-height: 300%;
          "
        >
          <span style="font-size: 14pt; line-height: 300%; font-family: 楷体"
          >报告日期：<span lang="EN-US">{{
              new Date().toLocaleDateString()
            }}</span></span
          >
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="margin-left: 63pt; text-align: left; line-height: 300%"
        >
          <span
              lang="EN-US"
              style="font-size: 14pt; line-height: 300%; font-family: 楷体"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="margin-left: 63pt; text-align: left; line-height: 300%"
        >
          <span
              lang="EN-US"
              style="font-size: 14pt; line-height: 300%; font-family: 楷体"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="margin-left: 63pt; text-align: left; line-height: 300%"
        >
          <span
              lang="EN-US"
              style="font-size: 14pt; line-height: 300%; font-family: 楷体"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 300%"
        >
          <span
              lang="EN-US"
              style="font-size: 15pt; height: 45pt; font-family: 宋体"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 300%"
        >
          <span style="font-size: 15pt; height: 45pt; font-family: 宋体"
          >报告说明<span lang="EN-US"><br /> </span></span
          ><span
            lang="EN-US"
            style="font-size: 14pt; line-height: 300%; font-family: 宋体"
        ><br />
            <br />
          </span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; text-indent: 52pt; line-height: 250%"
        >
          <span
              style="
              position: absolute;
              z-index: -1895826432;
              left: 0px;
              margin-left: 78px;
              margin-top: 21px;
              width: 315px;
              height: 314px;
            "
          ><img
              width="315"
              height="314"
              src="./word2.fld/image003.png"
              alt="9999" /></span
          ><span style="font-size: 12pt; line-height: 250%; font-family: 仿宋"
        >本报告由{{
            location
          }}单用途预付消费卡协同监管服务平台出具，依据报告期内系统获得的的信息数据生成。报告中的信息数据均由政府大数据管理机构、经营主体及第三方合作机构提供。上海市宝山区单用途预付消费卡协同监管服务平台承诺在信息数据采集、汇总、分析、展示等全过程中保持客观、中立立场。
            本报告仅向预付消费卡监管部门工作人员提供，须妥善保管。因保管不当造成信息数据泄露的，应承担相关行政或法律责任。<span
              lang="EN-US"
          ><br />
              <br /> </span
          ></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 200%"
        >
          <span style="font-size: 15pt; line-height: 200%; font-family: 仿宋"
          >风险及信用概览<span lang="EN-US"
          ><br />
              <br /> </span
          ></span>
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            width="477"
            style="width: 100%; border-collapse: collapse; border: none"
        >
          <tbody>
          <tr>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                <b
                ><span style="line-height: 200%; font-family: 仿宋"
                >综合评分</span
                ></b
                >
              </p>
            </td>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                <b
                ><span style="line-height: 200%; font-family: 仿宋"
                >风险等级</span
                ></b
                >
              </p>
            </td>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                <b
                ><span style="line-height: 200%; font-family: 仿宋"
                >预付码</span
                ></b
                >
              </p>
            </td>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                <b
                ><span style="line-height: 200%; font-family: 仿宋"
                >经营状况</span
                ></b
                >
              </p>
            </td>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                <b
                ><span style="line-height: 200%; font-family: 仿宋"
                >信用等级</span
                ></b
                >
              </p>
            </td>
          </tr>
          <tr>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 200%; font-family: 仿宋"
                  >--</span
                  ><span style="line-height: 200%; font-family: 仿宋"></span>
              </p>
            </td>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                  <span style="line-height: 200%; font-family: 仿宋">{{
                      operatorInfo.risk || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 200%; font-family: 仿宋"
                  ><img
                      width="25"
                      height="25"
                      id="图片 1"
                      :src="baseUrl + operatorInfo.safeCodeImg"
                      alt="333333"
                  />
                    {{ this.data.a.name }}</span
                  >
              </p>
            </td>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                <span style="line-height: 200%; font-family: 仿宋">--</span>
              </p>
            </td>
            <td
                width="95"
                valign="top"
                style="
                  width: 95.35pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 200%"
              >
                <span style="line-height: 200%; font-family: 仿宋">--</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 200%"
        >
          <b
          ><span style="font-size: 16pt; line-height: 200%; font-family: 宋体"
          >基本信息</span
          ></b
          >
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          >工商信息</span
          ></b
          >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 19.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">经营者名称</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      basicInfo.authority || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">统一社会信用代码</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      basicInfo.creditNo || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">类型</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      basicInfo.companyType || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">法定代表人</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      basicInfo.legalPerson || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">注册资本</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      basicInfo.capital || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">营业期限自</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋"
                  >{{ basicInfo.operationStartDate || "--" }} ~
                    {{ basicInfo.operationEndDate || "--" }}</span
                  >
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">登记状态</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      basicInfo.companyStatus || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">住所</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      basicInfo.companyAddress || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 38.85pt">
            <td
                width="97"
                style="
                  width: 96.9pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 38.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">经营范围</span>
              </p>
            </td>
            <td
                width="380"
                style="
                  width: 379.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 38.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      basicInfo.businessScope || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 150%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 150%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          >出资人信息</span
          ></b
          >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">股东</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                <span style="font-family: 仿宋">--</span>
              </p>
            </td>
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">出资比例</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                <span lang="EN-US" style="font-family: 仿宋">--</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">股东</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                <span style="font-family: 仿宋">--</span>
              </p>
            </td>
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">出资比例</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                <span lang="EN-US" style="font-family: 仿宋">--</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">股东</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                <span style="font-family: 仿宋">--</span>
              </p>
            </td>
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">出资比例</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                <span lang="EN-US" style="font-family: 仿宋">--</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 200%"
        >
          <b
          ><span style="font-size: 16pt; line-height: 200%; font-family: 仿宋"
          >公共信息</span
          ></b
          >
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; text-indent: 21pt; line-height: 150%"
        >
          <span style="line-height: 150%; font-family: 仿宋"
          >经营者主体成立于<span lang="EN-US">{{
              publicInfo.establishDate || "--"
            }}</span
          >，经营<span lang="EN-US">{{ publicInfo.manageTime || "--" }}</span
          >年， 登记状态正常，报告期内，经营者住房公积金缴纳人数<span
              lang="EN-US"
          >{{ publicInfo.payNum || "--" }}</span
          >人， 月缴金额<span lang="EN-US">{{
              publicInfo.payMonthMoney || "--"
            }}</span
          >元，缴纳状态{{
              publicInfo.payStatus ? publicInfo.payStatus : "--"
            }}。 当前经营者法定代表人个人公积金逾期<span lang="EN-US">{{
                publicInfo.payTime || "--"
              }}</span
            >个月，逾期金额<span lang="EN-US">{{
                publicInfo.payMoney || "--"
              }}</span
            >元。 目前经营者有<span lang="EN-US">{{
                publicInfo.manageNum || "--"
              }}</span
            >条违约记录， 电力欠费<span lang="EN-US">{{
                publicInfo.electricMoney || "--"
              }}</span
            >元，逾期<span lang="EN-US">{{
                publicInfo.electricTime || "--"
              }}</span
            >个月； 燃气欠费<span lang="EN-US">{{
                publicInfo.gasMoney || "--"
              }}</span
            >元，逾期<span lang="EN-US">{{ publicInfo.gasTime || "--" }}</span
            >个月； 截止<span lang="EN-US">{{
                publicInfo.waterTime || "--"
              }}</span
            >，水务累计欠费<span lang="EN-US">{{
                publicInfo.waterMoney || "--"
              }}</span
            >元。<span lang="EN-US"
            ><br />
              <br /> </span
            ></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          >电力欠费信息</span
          ></b
          >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr
              style="height: 19.85pt"
              v-for="(item, i) in publicOweInfo.electrics"
              :key="i"
          >
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">用电年月</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.electricTime || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">欠费金额</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.electricMoney || 0.0
                    }}</span>
              </p>
            </td>
          </tr>
          <!--      <div v-else>暂无数据</div>-->
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >水务欠费信息</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr
              style="height: 19.85pt"
              v-for="(item, i) in publicOweInfo.waters"
              :key="i"
          >
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">用水年月</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.electricTime || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">欠费金额</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.electricMoney || 0.0
                    }}</span>
              </p>
            </td>
          </tr>
          <!--      <div v-else>暂无数据</div>-->
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span lang="EN-US" style="line-height: 200%; font-family: 仿宋"
          ><br /> </span
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >燃气欠费信息</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr
              style="height: 19.85pt"
              v-for="(item, i) in publicOweInfo.gas"
              :key="i"
          >
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">用气年月</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.gasTime || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">欠费金额</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.gasMoney || 0.0
                    }}</span>
              </p>
            </td>
          </tr>
          <!--      <div v-else>暂无数据</div>-->
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span lang="EN-US" style="line-height: 200%; font-family: 仿宋"
          ><br /> </span
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >住房公积金缴存信息</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr
              style="height: 19.85pt"
              v-for="(item, i) in publicOweInfo.accumulationFundVO"
              :key="i"
          >
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">缴存年月</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.payTime || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">逾期金额</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.payMoney || 0.0
                    }}</span>
              </p>
            </td>
          </tr>
          <!--      <div v-else>暂无数据</div>-->
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span lang="EN-US" style="line-height: 200%; font-family: 仿宋"
          ><br /> </span
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >公积金个贷逾期信息</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr
              style="height: 19.85pt"
              v-for="(item, i) in publicOweInfo.overdues"
              :key="i"
          >
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">缴存年月</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.loanDate || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">逾期金额</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.pastAmount || 0.0
                    }}</span>
              </p>
            </td>
          </tr>
          <!--      <div v-else>暂无数据</div>-->
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >社保缴纳</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr
              style="height: 19.85pt"
              v-for="(item, i) in publicOweInfo.shebaoInfoVO"
              :key="i"
          >
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">缴存年月</span>
              </p>
            </td>
            <td
                width="142"
                style="
                  width: 141.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.payTime || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">缴纳人数</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 140.55pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      item.payNum || 0.0
                    }}</span>
              </p>
            </td>
          </tr>
          <!--      <div v-else>暂无数据</div>-->
          </tbody>
        </table>

        <!--    <p class="MsoNormal" align="left" style="text-align:left;line-height:150%"><span lang="EN-US"-->
        <!--                                                                                     style="line-height:150%;font-family:仿宋"><br/></span>-->
        <!--    </p>-->

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 150%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 16pt; line-height: 150%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 16pt; line-height: 150%; font-family: 仿宋"
        >经营情况</span
        ></b
        >
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; text-indent: 21pt; line-height: 150%"
        >
          <span style="line-height: 150%; font-family: 仿宋"
          >经营者主体于<span lang="EN-US">{{ manageInfo.time || "--" }}</span
          >完成信息对接和资金存管开户，于<span lang="EN-US">{{
              manageInfo.startTime || "--"
            }}</span
          >日首次有发卡交易记录， 报告期内，共有<span lang="EN-US">{{
              manageInfo.branchNum || "--"
            }}</span
          >家分店经营发卡业务，涉及会员<span lang="EN-US">{{
              manageInfo.vipNum || "--"
            }}</span
          >人， 发卡数量<span lang="EN-US">{{
              manageInfo.cardNum || "--"
            }}</span
          >笔，发卡金额<span lang="EN-US">{{
              manageInfo.cardMoney || "--"
            }}</span
          >元， 已兑付金额<span lang="EN-US">{{
              manageInfo.dfMoney || "--"
            }}</span
          >元，兑付比为<span lang="EN-US">{{
              manageInfo.dfRatio || "--"
            }}</span
          >， 资金管理方式为{{ manageInfo.type || "--" }}，存管账户为<span
              lang="EN-US"
          >0</span
          >元，目前风险状况为{{ manageInfo.fxzk || "--" }}。<span lang="EN-US"
          ><br /></span
          >共有<span lang="EN-US">{{ manageInfo.tsNum || "--" }}</span
          >次投诉，<span lang="EN-US">{{ manageInfo.cfNum || "--" }}0</span
          >条处罚记录，<span lang="EN-US"
          ><br />{{ manageInfo.fxczNum || "--" }}</span
          >条风险处置记录。</span
          >
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 150%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 150%; font-family: 仿宋"
          ><br /></span
          ></b>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          >经营者信息</span
          ></b
          >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">经营者名称</span>
              </p>
            </td>
            <td
                width="150"
                style="
                  width: 150pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      operatorInfo.busName || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="89"
                style="
                  width: 89.4pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">店铺数量</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 141.15pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋"
                  >{{ operatorInfo.busNum || "--" }} 家</span
                  >
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">员工人数</span>
              </p>
            </td>
            <td
                width="150"
                style="
                  width: 150pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋"
                  >{{ operatorInfo.people || "--" }} 人</span
                  ><span style="font-family: 仿宋"></span>
              </p>
            </td>
            <td
                width="89"
                style="
                  width: 89.4pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">所在地区</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 141.15pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      operatorInfo.address || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">所属行业</span>
              </p>
            </td>
            <td
                width="150"
                style="
                  width: 150pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      operatorInfo.industry || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="89"
                style="
                  width: 89.4pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">类型</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 141.15pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      operatorInfo.type || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">资金管理方式</span>
              </p>
            </td>
            <td
                width="150"
                style="
                  width: 150pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 仿宋">{{
                      operatorInfo.fundMode || "--"
                    }}</span>
              </p>
            </td>
            <td
                width="89"
                style="
                  width: 89.4pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">风险状况</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 141.15pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 楷体">{{
                      operatorInfo.risk || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">会员数</span>
              </p>
            </td>
            <td
                width="150"
                style="
                  width: 150pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋"
                  >{{ operatorInfo.vipNum || "--" }} 人</span
                  >
              </p>
            </td>
            <td
                width="89"
                style="
                  width: 89.4pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">风险处置</span>
              </p>
            </td>
            <td
                width="141"
                style="
                  width: 141.15pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span style="font-family: 楷体">{{
                      operatorInfo.damaged || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          <tr style="height: 19.85pt">
            <td
                width="96"
                style="
                  width: 96.25pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="right" style="text-align: right">
                <span style="font-family: 楷体">风险处置结果</span>
              </p>
            </td>
            <td
                width="381"
                colspan="3"
                style="
                  width: 380.55pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      operatorInfo.damagedResult || "--"
                    }}</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 150%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 150%; font-family: 仿宋"
          ><br /></span
          ></b>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          >预付交易状况</span
          ></b
          >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 28.2pt">
            <td
                width="76"
                valign="top"
                class="td-line"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; text-indent: 31.5pt"
              >
                  <span style="font-family: 楷体"
                  >类型<span lang="EN-US"><br /><br /> </span>日期</span
                  >
              </p>
            </td>
            <td
                width="99"
                style="
                  width: 98.75pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >交易笔数</span
                  >
              </p>
            </td>
            <td
                width="98"
                style="
                  width: 98.1pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >交易金额</span
                  >
              </p>
            </td>
            <td
                width="102"
                style="
                  width: 102.15pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >兑付金额</span
                  >
              </p>
            </td>
            <td
                width="102"
                style="
                  width: 102.15pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >兑付比</span
                  >
              </p>
            </td>
          </tr>
          <tr
              style="height: 19.85pt"
              v-for="(val, ind) in pdfinfo['预付交易']"
              :key="ind"
          >
            <td
                width="76"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 楷体"
                  >{{ val.time || "--" }}</span
                  >
              </p>
            </td>
            <td
                width="99"
                style="
                  width: 98.75pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ val.cardNum || 0 }}</span
                  ><span style="line-height: 150%; font-family: 仿宋">笔</span>
              </p>
            </td>
            <td
                width="99"
                style="
                  width: 98.75pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ val.cardMoney || 0.0 }}</span
                  >
              </p>
            </td>
            <td
                width="102"
                style="
                  width: 102.15pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ val.cashingMoney || 0.0 }}</span
                  >
              </p>
            </td>
            <td
                width="102"
                style="
                  width: 102.15pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ val.cashingRatio || "0.00%" }}</span
                  >
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >预收资金监管情况</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 28.2pt">
            <td
                width="76"
                valign="top"
                class="td-line short"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; text-indent: 31.5pt"
              >
                  <span style="font-family: 楷体"
                  >类型<span lang="EN-US"><br /><br /> </span>日期</span
                  >
              </p>
            </td>
            <td
                width="197"
                style="
                  width: 196.85pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >应存管发卡金额</span
                  >
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >存管资金</span
                  >
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >投保金额</span
                  >
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >担保或保函金额</span
                  >
              </p>
            </td>
          </tr>
          <tr
              style="height: 19.85pt"
              v-for="(val, ind) in pdfinfo['资金监管']"
              :key="ind"
          >
            <td
                width="76"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 楷体"
                  >{{ val.time || "--" }}</span
                  >
              </p>
            </td>
            <td
                width="197"
                style="
                  width: 196.85pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      val.totalMoney || 0.0
                    }}</span>
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      val.keepMoney || 0.0
                    }}</span>
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      val.insTotalMoney || 0.0
                    }}</span>
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      val.insKeepMoney || 0.0
                    }}</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >投诉受理状况</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 28.2pt">
            <td
                width="76"
                valign="top"
                class="td-line"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; text-indent: 31.5pt"
              >
                  <span style="font-family: 楷体"
                  >类型<span lang="EN-US"><br /><br /> </span>日期</span
                  >
              </p>
            </td>
            <td
                width="197"
                style="
                  width: 196.85pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >被投诉</span
                  >
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >被处罚</span
                  >
              </p>
            </td>
          </tr>
          <tr
              style="height: 19.85pt"
              v-for="(val, ind) in pdfinfo['投诉受理']"
              :key="ind"
          >
            <td
                width="76"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 楷体"
                  >{{ val.time || "--" }}</span
                  >
              </p>
            </td>
            <td
                width="197"
                style="
                  width: 196.85pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      val.num || 0
                    }}</span
                  ><span style="font-family: 仿宋">次</span>
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      val.complainNum || 0
                    }}</span
                  ><span style="font-family: 仿宋">次</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >风险处置情况</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 28.2pt">
            <td
                width="76"
                valign="top"
                class="td-line"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; text-indent: 31.5pt"
              >
                  <span style="font-family: 楷体"
                  >类型<span lang="EN-US"><br /><br /> </span>日期</span
                  >
              </p>
            </td>
            <td
                width="197"
                style="
                  width: 196.85pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >涉及人数</span
                  >
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >涉及金额</span
                  >
              </p>
            </td>
          </tr>
          <tr
              style="height: 19.85pt"
              v-for="(val, ind) in pdfinfo['风险处置记录']"
              :key="ind"
          >
            <td
                width="76"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 楷体"
                  >{{ val.time || "--" }}</span
                  >
              </p>
            </td>
            <td
                width="197"
                style="
                  width: 196.85pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      val.userNum || "0"
                    }}</span
                  ><span style="font-family: 仿宋">人</span>
              </p>
            </td>
            <td
                width="204"
                style="
                  width: 204.3pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span lang="EN-US" style="font-family: 仿宋">{{
                      val.totalPrice || "0"
                    }}</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >预付合同签约情况</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            width="478"
            style="width: 502.5pt; border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 22.7pt">
            <td
                width="120"
                valign="top"
                style="
                  width: 120pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >已签约</span
                  >
              </p>
            </td>
            <td
                width="118"
                valign="top"
                style="
                  width: 118.15pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >未签约</span
                  >
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.35pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: white;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                <span style="line-height: 150%; font-family: 楷体">履约</span>
              </p>
            </td>
            <td
                width="120"
                valign="top"
                style="
                  width: 120pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: white;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                <span style="line-height: 150%; font-family: 楷体">违约</span>
              </p>
            </td>
          </tr>
          <tr style="height: 22.7pt">
            <td
                width="120"
                valign="top"
                style="
                  width: 120pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ forCodeData.signNum }}</span
                  ><span style="line-height: 150%; font-family: 仿宋">份</span>
              </p>
            </td>
            <td
                width="118"
                valign="top"
                style="
                  width: 118.15pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ forCodeData.unSignNum }}</span
                  ><span style="line-height: 150%; font-family: 仿宋">份</span>
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.35pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ forCodeData.keepContractNum }}</span
                  ><span style="line-height: 150%; font-family: 仿宋">份</span>
              </p>
            </td>
            <td
                width="120"
                valign="top"
                style="
                  width: 120pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ forCodeData.unKeepContractNum }}</span
                  ><span style="line-height: 150%; font-family: 仿宋">份</span>
              </p>
            </td>
          </tr>
          <tr style="height: 22.7pt">
            <td
                width="120"
                valign="top"
                style="
                  width: 120pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ forCodeData.signRate }}</span
                  >
              </p>
            </td>
            <td
                width="118"
                valign="top"
                style="
                  width: 118.15pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ forCodeData.keepContractRate }}</span
                  >
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.35pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ forCodeData.signRate }}</span
                  >
              </p>
            </td>
            <td
                width="120"
                valign="top"
                style="
                  width: 120pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 仿宋"
                  >{{ forCodeData.unKeepContractRate }}</span
                  >
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >预付消费结构</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 22.7pt">
            <td
                width="238"
                colspan="2"
                valign="top"
                style="
                  width: 238.4pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >用户人群</span
                  ><span
                  lang="EN-US"
                  style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 楷体;
                    "
              >{{ forCodeDatatwo.amountNum }}</span
              ><span
                  style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 楷体;
                    "
              >人</span
              >
              </p>
            </td>
            <td
                width="238"
                colspan="2"
                valign="top"
                style="
                  width: 238.4pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >交易金额</span
                  ><span
                  lang="EN-US"
                  style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 楷体;
                    "
              >{{ forCodeDatatwo.consumersNum }}</span
              ><span
                  style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 楷体;
                    "
              >元</span
              >
              </p>
            </td>
          </tr>
          <tr style="height: 22.7pt">
            <td
                width="119"
                rowspan="5"
                style="
                  width: 119.2pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 仿宋;
                    "
                  >男：<span lang="EN-US"
                  >{{ forCodeDatatwo.maleAmountRate }}%<br />
                      <br /> </span
                  >女：<span lang="EN-US"
                  >{{ forCodeDatatwo.femaleAmountRate }}%</span
                  ></span
                  >
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >儿童：<span lang="EN-US"
                  >{{ forCodeDatatwo.childAmountRate }}%</span
                  ></span
                  >
              </p>
            </td>
            <td
                width="119"
                rowspan="5"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      style="
                      font-size: 12pt;
                      line-height: 150%;
                      font-family: 仿宋;
                    "
                  >男：<span lang="EN-US"
                  >{{ forCodeDatatwo.maleConsumersRate }}%<br />
                      <br /> </span
                  >女：<span lang="EN-US"
                  >{{ forCodeDatatwo.femaleConsumersRate }}%</span
                  ></span
                  >
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >儿童：<span lang="EN-US"
                  >{{ forCodeDatatwo.childConsumersRate }}%</span
                  ></span
                  >
              </p>
            </td>
          </tr>
          <tr style="height: 22.7pt">
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >少年：<span lang="EN-US"
                  >{{ forCodeDatatwo.juvenileAmountRate }}%</span
                  ></span
                  >
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >少年：<span lang="EN-US"
                  >{{ forCodeDatatwo.juvenileConsumersRate }}%</span
                  ></span
                  >
              </p>
            </td>
          </tr>
          <tr style="height: 22.7pt">
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >青年：<span lang="EN-US"
                  >{{ forCodeDatatwo.youthAmountRate }}%</span
                  ></span
                  >
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >青年：<span lang="EN-US"
                  >{{ forCodeDatatwo.youthConsumersRate }}%</span
                  ></span
                  >
              </p>
            </td>
          </tr>
          <tr style="height: 22.7pt">
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >中年：<span lang="EN-US"
                  >{{ forCodeDatatwo.middleAmountRate }}%</span
                  ></span
                  >
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >中年：<span lang="EN-US"
                  >{{ forCodeDatatwo.middleConsumersRate }}%</span
                  ></span
                  >
              </p>
            </td>
          </tr>
          <tr style="height: 22.7pt">
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >老年：<span lang="EN-US"
                  >{{ forCodeDatatwo.oldAmountRate }}%</span
                  ></span
                  >
              </p>
            </td>
            <td
                width="119"
                valign="top"
                style="
                  width: 119.2pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 22.7pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 仿宋"
                  >老年：<span lang="EN-US"
                  >{{ forCodeDatatwo.oldConsumersRate }}%</span
                  ></span
                  >
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <b
          ><span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br /> </span></b
          ><b
        ><span style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
        >门店防疫情况</span
        ></b
        >
        </p>

        <table
            class="MsoTableGrid"
            border="1"
            cellspacing="0"
            cellpadding="0"
            style="border-collapse: collapse; border: none"
        >
          <tbody>
          <tr style="height: 28.2pt">
            <td
                width="76"
                valign="top"
                class="td-line"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="left"
                  style="text-align: left; text-indent: 31.5pt"
              >
                  <span style="font-family: 楷体"
                  >类型<span lang="EN-US"><br /><br /> </span>日期</span
                  >
              </p>
            </td>
            <td
                width="131"
                style="
                  width: 130.6pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >门店防疫</span
                  >
              </p>
            </td>
            <td
                width="137"
                style="
                  width: 136.9pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >从业人员</span
                  >
              </p>
            </td>
            <td
                width="134"
                style="
                  width: 133.65pt;
                  border: solid windowtext 1pt;
                  border-left: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 28.2pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span style="line-height: 150%; font-family: 楷体"
                  >消费人员</span
                  >
              </p>
            </td>
          </tr>
          <tr
              style="height: 19.85pt"
              v-for="(val, ind) in pdfinfo['门店防疫情况']"
              :key="ind"
          >
            <td
                width="76"
                style="
                  width: 75.65pt;
                  border: solid windowtext 1pt;
                  border-top: none;
                  background: #f1f1f1;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p
                  class="MsoNormal"
                  align="center"
                  style="text-align: center; line-height: 150%"
              >
                  <span
                      lang="EN-US"
                      style="line-height: 150%; font-family: 楷体"
                  >{{ val.time || "--" }}</span
                  >
              </p>
            </td>
            <td
                width="131"
                style="
                  width: 130.6pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span style="font-family: 仿宋"
                  >正常<span lang="EN-US"
                  >{{ val.normalShopNum || "0" }} / </span
                  >异常<span lang="EN-US">{{
                      val.abnormalShopNum || "0"
                    }}</span></span
                  >
              </p>
            </td>
            <td
                width="137"
                style="
                  width: 136.9pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span style="font-family: 仿宋"
                  >正常<span lang="EN-US"
                  >{{ val.normalEmployeeNum || "0" }} / </span
                  >异常<span lang="EN-US">{{
                      val.abnormalShopNum || "0"
                    }}</span></span
                  >
              </p>
            </td>
            <td
                width="134"
                style="
                  width: 133.65pt;
                  border-top: none;
                  border-left: none;
                  border-bottom: solid windowtext 1pt;
                  border-right: solid windowtext 1pt;
                  padding: 0cm 5.4pt 0cm 5.4pt;
                  height: 19.85pt;
                "
            >
              <p class="MsoNormal" align="center" style="text-align: center">
                  <span style="font-family: 仿宋"
                  >正常<span lang="EN-US"
                  >{{ val.normalConsumerNum || "0" }} / </span
                  >异常<span lang="EN-US">{{
                      val.abnormalShopNum || "0"
                    }}</span></span
                  >
              </p>
            </td>
          </tr>
          </tbody>
        </table>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="text-align: left; line-height: 200%"
        >
          <span
              lang="EN-US"
              style="font-size: 12pt; line-height: 200%; font-family: 仿宋"
          ><br
          /></span>
        </p>

        <p
            class="MsoNormal"
            align="center"
            style="text-align: center; line-height: 200%"
        >
          <span style="font-size: 15pt; line-height: 200%; font-family: 仿宋"
          >结论及风险提示<span lang="EN-US"
          ><br />
              <br /> </span
          ></span>
        </p>

        <p
            class="MsoNormal"
            align="left"
            style="
            margin-left: 21pt;
            text-align: left;
            text-indent: -21pt;
            line-height: 200%;
            display: flex;
          "
            v-for="(val, ind) in dataval"
            :key="ind"
        >
          <span
              v-if="ind < dataval.length - 1"
              lang="EN-US"
              style="width: 30px; line-height: 200%; font-family: Wingdings"
          >²<span style="font: 7pt 'Times New Roman'"><br /> </span
          ></span>
          <span
              v-else
              lang="EN-US"
              style="width: 44px; line-height: 200%; font-family: Wingdings"
          >²<span style="font: 7pt 'Times New Roman'"><br /> </span
          ></span>
          <span style="line-height: 200%; font-family: 仿宋">{{ val }}</span>
        </p>

        <!--      <p class="MsoNormal" align="left" style="margin-left:21.0pt;text-align:left;-->
        <!--text-indent:-21.0pt;line-height:200%"><span lang="EN-US" style="line-height:200%;-->
        <!--font-family:Wingdings">²<span style="font:7.0pt 'Times New Roman'"><br/> </span></span><span-->
        <!--          style="line-height:200%;font-family:仿宋">该经营者防疫情况有待提高。</span></p>-->

        <!--      <p class="MsoNormal" align="left" style="margin-left:21.0pt;text-align:left;-->
        <!--text-indent:-21.0pt;line-height:200%"><span lang="EN-US" style="line-height:200%;-->
        <!--font-family:Wingdings">²<span style="font:7.0pt 'Times New Roman'"><br/> </span></span><span style="position:absolute;z-index:-1895824384;left:0px;margin-left:77px;-->
        <!--margin-top:76px;width:315px;height:314px"><img width="315" height="314" src="./word2.fld/image005.png"-->
        <!--                                               alt="9999"></span><span style="line-height:200%;font-family:仿宋">截至<span-->
        <!--          lang="EN-US">2021</span>年<span lang="EN-US">5</span>月该经营者有大量预付卡待兑付。</span></p>-->
      </div>

      <div></div>
    </div>
  </div>

  <!--endprint-->
</template>

<script type="text/javascript">
import { baseURL } from "@/api/config";
import {
  getSafeCodeColor,
  basicInfo,
  publicInfo,
  publicOweInfo,
  manageInfo,
  operatorInfo,
  transactionsInfo,
  moneyInfo,
  insuredInfo,
  complainInfo,
  damagedInfo,
  contractStatistics,
  consumerStatistics,
  epidemicPreventionCumulative,
  getPdfListInfo,
} from "@/api/intelligent";

export default {
  name: "HomePdfDownload",
  data() {
    return {
      showBtn: true,
      location: "上海市宝山区",
      baseUrl: baseURL,
      manageInfo: {},
      operatorInfo: {
        safeCodeImg: "",
      },
      basicInfo: {
        authority: "",
        creditNo: "",
        companyType: "",
        legalPerson: "",
        capital: "",
        establishDate: "",
        operationEndDate: "",
        operationStartDate: "",
        companyStatus: "",
        companyAddress: "",
        businessScope: "",
      },
      publicInfo: {
        establishDate: "",
        manageTime: "",
        payNum: "",
        payMonthMoney: "",
        payStatus: "",
        payTime: "",
        payMoney: "",
        manageNum: "",
        electricMoney: "",
        electricTime: "",
        gasMoney: "",
        gasTime: "",
        waterTime: "",
        waterMoney: "",
      },
      publicOweInfo: {
        accumulationFundVO: [
          { payTime: "2021-1", payMoney: "0" },
          { payTime: "2021-2", payMoney: "0" },
          { payTime: "2021-3", payMoney: "0" },
          { payTime: "2021-4", payMoney: "0" },
          { payTime: "2021-5", payMoney: "0" },
          { payTime: "2021-6", payMoney: "0" },
        ],
        shebaoInfoVO: [
          { payTime: "2021-1", payNum: "0" },
          { payTime: "2021-2", payNum: "0" },
          { payTime: "2021-3", payNum: "0" },
          { payTime: "2021-4", payNum: "0" },
          { payTime: "2021-5", payNum: "0" },
          { payTime: "2021-6", payNum: "0" },
        ],
        overdues: [
          { loanDate: "2021-1", pastAmount: "0" },
          { loanDate: "2021-2", pastAmount: "0" },
          { loanDate: "2021-3", pastAmount: "0" },
          { loanDate: "2021-4", pastAmount: "0" },
          { loanDate: "2021-5", pastAmount: "0" },
          { loanDate: "2021-6", pastAmount: "0" },
        ],
        waters: [
          { electricTime: "2021-1", electricMoney: "0" },
          { electricTime: "2021-2", electricMoney: "0" },
          { electricTime: "2021-3", electricMoney: "0" },
          { electricTime: "2021-4", electricMoney: "0" },
          { electricTime: "2021-5", electricMoney: "0" },
          { electricTime: "2021-6", electricMoney: "0" },
        ],
        gas: [
          { gasTime: "2021-1", gasMoney: "0" },
          { gasTime: "2021-2", gasMoney: "0" },
          { gasTime: "2021-3", gasMoney: "0" },
          { gasTime: "2021-4", gasMoney: "0" },
          { gasTime: "2021-5", gasMoney: "0" },
          { gasTime: "2021-6", gasMoney: "0" },
        ],
        electrics: [
          { electricTime: "2021-1", electricMoney: "0" },
          { electricTime: "2021-2", electricMoney: "0" },
          { electricTime: "2021-3", electricMoney: "0" },
          { electricTime: "2021-4", electricMoney: "0" },
          { electricTime: "2021-5", electricMoney: "0" },
          { electricTime: "2021-6", electricMoney: "0" },
        ],
        payMoney: "",
        payNum: "",
        payTime: "",

        waterMoney: "",
        waterTime: "",
      },
      transactionsInfo: {
        cashs: [],
        nums: [],
        ratios: [],
        trans: [],
        x_axis: [],
      },
      moneyInfo: {
        cgs: [],
        trans: [],
        x_axis: [],
      },
      complainInfo: {
        complaints: [],
        x_axis: [],
      },
      pdfinfo: {
        投诉受理: [],
        资金监管: [],
        门店防疫情况: [],
        预付交易: [],
        风险处置记录: [],
      },
      forCodeData: {
        contractStatus: 0,
        keepContractNum: 0,
        keepContractRate: "0.00%",
        num: 0,
        signNum: 0,
        signRate: "0.00%",
        unKeepContractNum: 0,
        unKeepContractRate: "0.00%",
        unSignNum: 0,
        unSignRate: "0.00%",
      },
      forCodeDatatwo: {
        amountNum: 0,
        childAmountRate: "0.00",
        childConsumersRate: "0.00",
        consumersNum: 0,
        femaleAmountRate: "0.00",
        femaleConsumersRate: "0.00",
        juvenileAmountRate: "0.00",
        juvenileConsumersRate: "0.00",
        maleAmountRate: "0.00",
        maleConsumersRate: "0.00",
        middleAmountRate: "0.00",
        middleConsumersRate: "0.00",
        oldAmountRate: "0.00",
        oldConsumersRate: "0.00",
        youthAmountRate: "0.00",
        youthConsumersRate: "0.00",
      },
      colorList: ["未备案", "红码", "黄码", "绿码", "不发卡"],
      dataval: [],
      data: {
        a: {
          name: "红码",
        },
        b: {
          name: "中风险",
          istrue: "≥50%",
        },
        c: {
          name1: "兑付比",
          name1istrue: 0,
          name1number: "",

          name2: "交易",
          name2istrue: "无",

          name3: "投诉",
          name3istrue: "无",
          name3number: "",

          name4: "防疫",
          name4istrue: "",
        },
        d: {
          name1: "欠费纪录",
          name1istrue: "无",
          name1number: 0,
          name2: "处罚纪录",
          name2istrue: "无",
          name2number: 0,
          name3: "信用等级",
          name3istrue: "",
        },
      },
      riskwarning: [
        {
          name: "预付码",
          child: [
            {
              name: "未备案",
              val: "该经营者未信息对接，预付消费卡经营涉嫌违规违法。",
            },
            {
              name: "红码",
              val: "该经营者已信息对接，已开通资金存管或履约保证保险功能，但尚未实际存管资金或投保，风险状况被判定为高风险。",
            },
            {
              name: "黄码",
              val: "该经营者已信息对接，资金存管账户已激活，预收资金已部分存管或投保，风险状况被判定为中风险。",
            },
            {
              name: "绿码",
              val: "该经营者已信息对接，预收资金已全部存管或投保，风险状况被判定为低风险。",
            },
            {
              name: "不发卡",
              val: "该黑码代表预付消费卡经营者被投诉，涉嫌违规。",
            },
          ],
        },
        {
          name: "风险状况",
          child: [
            {
              name: "高风险",
              val: "该经营者风险较高，有待相关部门待进一步核查。",
            },
            {
              name: "中风险",
              val: "该经营者风险中等，需定期关注。",
            },
            {
              name: "低风险",
              val: "该经营者风险较低，经营状况良好。",
              // child: [
              //   {
              //     name: "90份以上",
              //     val: "该经营者信用程度高，有优良的信用记录，资金实力雄厚，资产质量优良，经济效益明显，不确定因素对其经营与发展影响极小，履约能力强。",
              //   },
              //   {
              //     name: "90份以下",
              //     val: "该经营者风险较低，经营状况良好。",
              //   },
              // ],
            },
          ],
        },
        {
          name: "经营状况",
          child: [
            {
              name: "兑付比",
              child: [
                {
                  name: "=0%",
                  val: "该经营者预付卡兑付情况异常，有待进一步核查。",
                },
                {
                  name: "≥50%",
                  val: "该经营者预付卡兑付情况正常，经营状况稳定。",
                },
                {
                  name: "≤50%",
                  val: "截止，该经营者有大量预付卡待兑付，需定期关注。",
                },
              ],
            },
            {
              name: "交易",
              child: [
                {
                  name: "无",
                  val: "该经营者近1个月内无预付卡交易记录，经营状况异常。",
                },
                {
                  name: "有",
                  val: "该经营者近1个月内预付卡交易记录正常。",
                },
              ],
            },
            {
              name: "投诉",
              child: [
                {
                  name: "无",
                  val: "该经营者近三个月无投诉记录，服务状况良好。",
                },
                {
                  name: "有",
                  val: "该经营者近三个月有次投诉记录，服务状况有待提升。",
                },
              ],
            },
            {
              name: "防疫",
              child: [
                {
                  name: "是",
                  val: "该经营者近1个月内日常防疫工作异常。",
                },
                {
                  name: "否",
                  val: "该经营者近1个月内日常防疫工作正常。",
                },
              ],
            },
          ],
        },
        {
          name: "信用状况",
          child: [
            {
              name: "欠费记录",
              child: [
                {
                  name: "无",
                  val: "",
                },
                {
                  name: "有",
                  val: "该经营者近六个月有次欠费记录，信用状况异常。",
                },
              ],
            },
            {
              name: "处罚记录",
              child: [
                {
                  name: "无",
                  val: "",
                },
                {
                  name: "有",
                  val: "该经营者近3年有次处罚记录，信用状况异常。",
                },
              ],
            },
            {
              name: "信用等级",
              child: [
                {
                  name: "AAA",
                  val: "该经营者的信用程度高，有优良的信用记录，预付交易活跃，预付卡兑付比高，经营状况良好，不确定因素对其经营与发展的影响极小，履约能力强。",
                },
                {
                  name: "AA",
                  val: "该经营者的信用程度较高，预付卡兑付比较高。该类企业具有优良的信用记录，经营状况较好，盈利水平较高，发展前景较为广阔，不确定因素对其经营与发展的影响很小。",
                },
                {
                  name: "A",
                  val: "该经营者的信用程度良好，预付卡兑付比一般，在正常情况下偿还债务没有问题。该类企业具有良好的信用记录，经营处于良性循环状态，但是可能存在一些影响其未来经营与发展的不确定因素，进而削弱其盈利能力和履约能力。",
                },
                {
                  name: "BBB",
                  val: "该经营者的信用程度一般，履约能力一般。该类企业的信用记录正常，但其经营状况、盈利水平及未来发展易受不确定因素的影响，履约能力有波动。",
                },
                {
                  name: "BB",
                  val: "该经营者的信用程度较差，履约能力不足。该类企业有较多不良信用记录，未来前景不明朗，含有投机性因素。",
                },
                {
                  name: "B",
                  val: "该经营者的信用程度差，履约能力较弱，预付消费需谨慎，监管需关注。",
                },
                {
                  name: "CCC",
                  val: "该经营者的信用很差，几乎没有履约能力，预付消费需谨慎，监管需关注。",
                },
                {
                  name: "CC",
                  val: "该经营者的信用极差，没有履约能力，预付消费需谨慎，监管需关注。",
                },
                {
                  name: "C",
                  val: "该经营者无信用，请不要预付消费，待监管部门核查。",
                },
                {
                  name: "D",
                  val: "该经营者已破产倒闭或关门跑路、停业歇业。",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  watch: {
    data: {
      handler: function (val) {
        // this.riskwarningdata(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.location = JSON.parse(localStorage.getItem("rowData")).location;

    console.log(baseURL);
    this.intData();
  },
  methods: {
    intData() {
      // console.log(this.$route)
      if (this.$route.query.riskBusId) {
        this.riskwarningdata(this.data);
        this.getPdfListInfo();
        this.getSafeCodeColor();
        this.getBasicInfo();
        this.getPublicInfo();
        this.getPublicOweInfo();
        this.getManageInfo();
        this.getOperatorInfo();
        this.getTransactionsInfo();
        this.getMoneyInfo();
        this.getInsuredInfo();
        this.getComplainInfo();
        this.getDamagedInfo();
        this.getcontractStatistics();
        this.getconsumerStatistics();
        this.getepidemicPreventionCumulative();
      }
    },
    prints() {
      this.showBtn = !this.showBtn;
      setTimeout(() => {
        window.print();
        this.showBtn = true;
      }, 200);
    },
    getPdfListInfo() {
      getPdfListInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        console.log(res);
        if (res.code == 500) {
          this.pdfinfo;
        } else if (res.code == 0) {
          this.pdfinfo = res.data;
          if (this.pdfinfo["预付交易"].length) {
            this.data.c.name2istrue = "有";
          }
          if (this.pdfinfo["投诉受理"].length) {
            this.data.c.name3istrue = "有";
            this.data.c.name3number = this.pdfinfo["投诉受理"].length;
          }
          if (!this.pdfinfo["门店防疫情况"].length) {
            this.data.c.name4istrue = "否";
          }
        }
      });
    },
    getSafeCodeColor() {
      getSafeCodeColor({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        console.log(res);
        this.data.a.name = this.colorList[res.data.safeCodeColor - 1];
      });
    },
    riskwarningdata(data) {
      let riskwarning = this.riskwarning;

      let dataval = [];
      let arr =[];
      if (data.a.name !== undefined && data.a.name !== "") {
        riskwarning[0].child.map((v) => {
          if (data.a.name == v.name) {
            dataval.push(v.val);
          }
        });
      }
      if (data.b.name !== undefined && data.b.name !== "") {
        riskwarning[1].child.map((v) => {
          if (data.b.name == v.name) {
            dataval.push(v.val);
            // if (data.b.name == "低风险") {
            //   v.child.map((x, y) => {
            //     if (data.b.istrue == x.name) {
            //       dataval.push(x.val);
            //     }
            //   });
            // } else {
            //   dataval.push(v.val);
            // }
          }
        });
      }

      if (data.c.name1 !== undefined&&data.c.name1 !== "") {
        if (data.c.name1istrue == 0) {
          dataval.push(riskwarning[2].child[0].child[0].val);
        } else if (data.c.name1istrue <= 50) {
          dataval.push(riskwarning[2].child[0].child[2].val);
        } else if (data.c.name1istrue > 50) {
          dataval.push(riskwarning[2].child[0].child[1].val);
        }
      }

      if (data.c.name2 !== undefined&&data.c.name2 !== "") {
        riskwarning[2].child[1].child.map((v) => {
          if (data.c.name2istrue == v.name) {
            dataval.push(v.val);
          }
        });
      }

      if (data.c.name3 !== undefined&&data.c.name3 !== "") {
        riskwarning[2].child[2].child.map((v) => {
          if (data.c.name3istrue == v.name) {
            if (data.c.name3istrue == "有") {
              let val = v.val;
              dataval.push(val.slice(0, 9) + data.c.name3number + val.slice(9));
            } else {
              dataval.push(v.val);
            }
          }
        });
      }

      if (data.c.name4 !== undefined&&data.c.name4 !== "") {
        riskwarning[2].child[3].child.map((v) => {
          if (data.c.name4istrue == v.name) {
            dataval.push(v.val);
          }
        });
      }

      if (data.d.name1 !== undefined&&data.d.name1 !== "") {
        riskwarning[3].child[0].child.map((v) => {
          if (data.d.name1istrue == v.name) {
            if (data.d.name1istrue == "有") {
              let val = v.val;
              dataval.push(
                  val.slice(0, 10) + data.d.name1number + val.slice(10)
              );
            } else {
              dataval.push(v.val);
            }
          }
        });
      }

      if (data.d.name2 !== undefined&&data.d.name2 !== "") {
        riskwarning[3].child[1].child.map((v) => {
          if (data.d.name2istrue == v.name) {
            if (data.d.name2istrue == "有") {
              let val = v.val;
              dataval.push(val.slice(0, 8) + data.d.name2number + val.slice(8));
            } else {
              dataval.push(v.val);
            }
          }
        });
      }

      if (data.d.name3 !== undefined&&data.d.name3 !== "") {
        riskwarning[3].child[2].child.map((v) => {
          if (data.d.name3istrue == v.name) {
            dataval.push(v.val);
          }
        });
      }
      console.log(3333,dataval);

      for(let i=0;i<=dataval.length-1;i++){
        if(dataval[i].trim()!= ""){
          arr.push(dataval[i])
        }
      }

      this.dataval= arr;

      // this.dataval = dataval;
      console.log(this.dataval, 1111,arr,2222,dataval);
    },
    getBasicInfo() {
      basicInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.basicInfo;
        } else if (res.code == 0) {
          this.basicInfo = res.data;
        }
      });
    },
    getPublicInfo() {
      publicInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.publicInfo;
        } else if (res.code == 0) {
          this.publicInfo = res.data;
        }
      });
    },
    getPublicOweInfo() {
      publicOweInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.publicOweInfo;
        } else if (res.code == 0) {
          if (res.data.accumulationFundVO.length) {
            this.publicOweInfo.accumulationFundVO = res.data.accumulationFundVO;
          }
          if (res.data.shebaoInfoVO.length) {
            this.publicOweInfo.shebaoInfoVO = res.data.shebaoInfoVO;
          }
          if (res.data.overdues.length) {
            this.publicOweInfo.overdues = res.data.overdues;
          }
          if (res.data.waters.length) {
            this.publicOweInfo.waters = res.data.waters;
          }
          if (res.data.gas.length) {
            this.publicOweInfo.gas = res.data.gas;
          }
          if (res.data.electrics.length) {
            this.publicOweInfo.electrics = res.data.electrics;
          }

          // this.publicOweInfo = res.data;

          if (
              res.data.accumulationFundVO.length ||
              res.data.shebaoInfoVO.length ||
              res.data.overdues.length ||
              res.data.waters.length ||
              res.data.gas.length ||
              res.data.electrics.length
          ) {
            this.data.d.name1istrue = "有";
          }
        }
      });
    },
    getManageInfo() {
      manageInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.manageInfo = res.data;
        this.data.c.name1istrue = this.manageInfo.dfRatio;
      });
    },
    getOperatorInfo() {
      operatorInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.operatorInfo = res.data;
        this.data.b.name = this.operatorInfo.risk;
      });
    },
    getTransactionsInfo() {
      transactionsInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio5,
      }).then((res) => {
        this.transactionsInfo = res.data;
        this.newlineChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "交易笔数",
              data: res.data.nums,
            },
            {
              name: "交易金额",
              data: res.data.trans,
            },
            {
              name: "兑付金额",
              data: res.data.cashs,
            },
            {
              name: "兑付比",
              data: res.data.ratios,
            },
          ],
        };
      });
    },
    getMoneyInfo() {
      moneyInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio2,
      }).then((res) => {
        this.moneyInfo = res.data;
        this.lineChartData2 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "应存管发卡金额",
              data: res.data.trans,
            },
            {
              name: "存管资金",
              data: res.data.cgs,
            },
          ],
        };
      });
    },
    getInsuredInfo() {
      insuredInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio3,
      }).then((res) => {
        this.insuredInfo = res.data;
        this.lineChartData3 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "应保险发卡金额",
              data: res.data.moneys,
            },
            {
              name: "保额",
              data: res.data.bes,
            },
          ],
        };
      });
    },
    getComplainInfo() {
      complainInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio4,
      }).then((res) => {
        this.complainInfo = res.data;
        this.lineChartData4 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "投诉",
              data: res.data.complaints,
            },
          ],
        };
      });
    },
    getDamagedInfo() {
      damagedInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.damagedInfo = res.data;
      });
    },

    getcontractStatistics() {
      contractStatistics({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.forCodeData = res.data;
      });
    },
    getconsumerStatistics() {
      consumerStatistics({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.forCodeDatatwo = res.data;
      });
    },
    getepidemicPreventionCumulative() {
      epidemicPreventionCumulative({
        infoType: this.tabPosition2,
        riskBusId: this.$route.query.riskBusId,
        type: this.radio1,
      }).then((res) => {
        this.barChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name:
                  this.tabPosition2 == 1
                      ? "正常门店数"
                      : this.tabPosition2 == 2
                      ? "正常从业人数"
                      : "正常消费人数",
              data:
                  this.tabPosition2 == 1
                      ? res.data.normalShopNum
                      : this.tabPosition2 == 2
                      ? res.data.normalEmployeeNum
                      : res.data.normalConsumerNum,
            },
            {
              name:
                  this.tabPosition2 == 1
                      ? "异常门店数"
                      : this.tabPosition2 == 2
                      ? "异常从业人数"
                      : "异常消费人数",
              data:
                  this.tabPosition2 == 1
                      ? res.data.abnormalShopNum
                      : this.tabPosition2 == 2
                      ? res.data.abnormalEmployeeNum
                      : res.data.abnormalConsumerNum,
            },
          ],
        };
      });
    },
  },
};
</script>

<style>
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
input,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
textarea,
article,
aside,
audio,
canvas,
figure,
footer,
header,
mark,
menu,
nav,
section,
time,
video {
  margin: initial;
  padding: initial;
}

table {
  border-collapse: initial;
  border-spacing: initial;
}

body {
  min-width: initial;
  font: initial;
  background: initial;
  color: initial;
}

.td-line {
  position: relative;
}
.td-line::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: block;
  height: 1px;
  width: 114%;
  background: #000;
  transform: rotate(28deg);
  transform-origin: left top;
}

.short::before {
  width: 119%;
  transform: rotate(34deg);
}

@font-face {
  font-family: Wingdings;
  panose-1: 5 0 0 0 0 0 0 0 0 0;
}

@font-face {
  font-family: 宋体;
}

@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}

@font-face {
  font-family: "\@宋体";
}

@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}

@font-face {
  font-family: 仿宋;
}

@font-face {
  font-family: "\@仿宋";
}

@font-face {
  font-family: "等线 Light";
}

@font-face {
  font-family: "\@等线 Light";
}

@font-face {
  font-family: 楷体;
}

@font-face {
  font-family: "\@楷体";
}

.pdf-box {
  width: 670px;
  margin: 0 auto 40px;
}

/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin: 0cm;
  margin-bottom: 0.0001pt;
  text-align: justify;
  text-justify: inter-ideograph;
  font-size: 10.5pt;
  font-family: Calibri;
  min-height: 20px;
}

p.MsoHeader,
li.MsoHeader,
div.MsoHeader {
  margin: 0cm;
  margin-bottom: 0.0001pt;
  text-align: justify;
  text-justify: inter-ideograph;
  layout-grid-mode: char;
  border: none;
  padding: 0cm;
  font-size: 9pt;
  font-family: Calibri;
}

p.MsoFooter,
li.MsoFooter,
div.MsoFooter {
  margin: 0cm;
  margin-bottom: 0.0001pt;
  layout-grid-mode: char;
  font-size: 9pt;
  font-family: Calibri;
}

/* Page Definitions */
@page WordSection1 {
  size: 595.3pt 841.9pt;
  margin: 60.65pt 64.65pt 60.65pt 64.65pt;
  layout-grid: 15.6pt;
}

div.WordSection1 {
  page: WordSection1;
}

/* List Definitions */
ol {
  margin-bottom: 0cm;
}

ul {
  margin-bottom: 0cm;
}
</style>
